<script setup>
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'

const store = useStore()
// computed
const unit = computed(() => 'Stück')
const getFromSub2Config = computed(() => store.getters['system/getFromSub2Config'])
const getSelection = computed(() => store.getters['system/getSelectionSubTwo'])

// methods
function onChangeOption (e) {
  store.dispatch('system/setSubTwoOptions', {
    id: e.target.value,
    attr: e.target.dataset.step,
    checked: e.target.checked
  })
}
function onChangeDimension (e) {
  store.dispatch('system/setDimension', {
    config: e.target.id,
    type: 'width',
    value: e.target.value
  })
}
function sub (e) {
  store.dispatch('system/setSubTwoQuantity', {
    value: e.target.value,
    action: '-',
    id: e.target.id,
    step: e.target.dataset.parent_id
  })
}
function add (e) {
  store.dispatch('system/setSubTwoQuantity', {
    value: e.target.value,
    action: '+',
    id: e.target.id,
    step: e.target.dataset.parent_id
  })
}
</script>
<template>
  <div class="row">
    <div class="col-md-3">
      <div>
        <label class="">Breite</label>
        <div class="input-group mb-2">
          <select class="form-select" id="sub_2" @change="onChangeDimension">
            <option v-for="selectOption in getSelection" :value="selectOption.value" :key="selectOption.value" :selected="selectOption.selected">{{ selectOption.label }}</option>
          </select>
          <div class="input-group-text">Zentimeter</div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12" v-for="(step, index) in getFromSub2Config" :key="index">
      <div class="row">
        <div class="col-sm-12">
          <p><strong>{{ step.name }}:</strong></p>
        </div>
      </div>
      <div class="row">
        <div v-for="(option, index) in step.options" :key="index" class="col-sm-4">
          <div v-if="option.type === 'select'" class="form-check">
            <input
              type="radio"
              :id="option.id"
              :value="option.id"
              :name="option.dname"
              :data-step="step.id"
              :checked="option.selected"
              class="form-check-input"
              :disabled="option.disabled"
              @change="onChangeOption"
            >
            <label class="form-check-label" :for="option.id">
              {{ option.name }} <tooltip :tooltip="option.tooltip" style="display:inline-block"><span class="fa fa-info-circle text-info"></span></tooltip>
            </label>
          </div>
          <!-- check -->
          <div v-else-if="option.type === 'check'" class="form-check">
            <input
              type="checkbox"
              :id="option.id"
              :value="option.id"
              :name="step.id"
              :data-step="step.id"
              :checked="option.selected"
              class="form-check-input"
              :disabled="option.disabled"
              @change="onChangeOption"
            >
            <label class="form-check-label" :for="option.id">
              {{ option.name }} <tooltip :tooltip="option.tooltip" style="display:inline-block"><span class="fa fa-info-circle text-info"></span></tooltip>
            </label>
          </div>
          <!-- count -->
          <div v-if="option.type === 'count'" class="input-group">
            <input type="text" :value="option.value + ' ' + unit" class="form-control" disabled>
            <button class="btn btn-primary"  @click="sub" :id="option.id" :data-parent_id="step.id" :disabled="option.disabled">-</button>
            <button class="btn btn-primary" @click="add" :id="option.id" :data-parent_id="step.id" :disabled="option.disabled">+</button>
          </div>
      </div>
      </div>
    </div>
  </div>
</template>
